import React, { useState, useEffect, useCallback } from "react";
import { Button, Form, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useSearchParams } from "react-router-dom";
import { MeetingPrice } from "../../config/service";

const FavourableTimes = ({
  formData,
  setformData,
  setSelectStep,
  doctorObj,
  utm,
  storagePartnerId,
}) => {
  const { t } = useTranslation();
  let [searchParams] = useSearchParams();
  let lang = localStorage.getItem("lang");
  const [refStep] = useState(searchParams.get("mId"));
  const [mappingArray, setMappingArray] = useState([]);
  const [selectedTime, setSelectedTime] = useState([]);
  const [timeData, setTimeData] = useState([]);
  const [flag, setFlag] = useState(false);
  const [detailSec, setDetailSec] = useState(false);
  const [priceamount, setPriceAmount] = useState();
  const [dayTimes] = useState([
    { hebrew: "בוקר", english: "Morning" },
    { hebrew: "צהרים", english: "Afternoon" },
    { hebrew: "ערב", english: "Evening" },
  ]);
  let heading = ["a day", "Until 12:00", "16:00 - 12:00", "after 16:00"];
  let [day_array] = useState(["א'", "ב'", "ג'", "ד'", "ה'", "ו'", "ש'"]);
  const [submitError, setSubmitError] = useState("");

  const getData = useCallback(() => {
    function addDays(days) {
      var result = new Date();
      result.setDate(result.getDate() + days + 2);
      let day = day_array[result.getDay()];
      let currentdate = result.getDate();
      let currentMonth = result.getMonth() + 1;
      let currentYear = result.getFullYear();
      return {
        day,
        currentdate,
        currentMonth,
        currentYear,
        shift: dayTimes,
      };
    }

    let date_array = [
      { day: addDays(0) },
      { day: addDays(1) },
      { day: addDays(2) },
      { day: addDays(3) },
      { day: addDays(4) },
      { day: addDays(5) },
      { day: addDays(6) },
    ];
    setMappingArray(date_array);
  }, [dayTimes, day_array]);

  const slectedTimeFunction = (
    dateInfo,
    timeInfo,
    pindex,
    childindex,
    falg
  ) => {
    const month =
      dateInfo.day.currentMonth > 9
        ? dateInfo.day.currentMonth
        : `0${dateInfo.day.currentMonth}`;
    const daySelect =
      dateInfo.day.currentdate > 9
        ? dateInfo.day.currentdate
        : `0${dateInfo.day.currentdate}`;
    const timing = `${dateInfo.day.currentYear}-${month}-${daySelect} ${
      timeInfo == "צהרים"
        ? "12:00:00"
        : timeInfo == "בוקר"
        ? "08:00:00"
        : "16:00:00"
    }`;
    const RequestDate = timing;
    const index = selectedTime.indexOf(RequestDate);
    if (index > -1) {
      selectedTime.splice(index, 1);
    } else {
      setSelectedTime([...selectedTime, RequestDate]);
    }

    let copymappingArray = JSON.parse(JSON.stringify(mappingArray));
    copymappingArray[pindex].day.shift[childindex].isSelect = !falg;
    setMappingArray(copymappingArray);

    // copymappingArray.map(data=>{
    //   return(
    //     data.day.shift.map(d=>{
    //       return d.isSelect
    //     })?
    //     setTimeData([...timeData,`${data.day.currentdate}/${data.day.currentMonth}/${data.day.currentYear},${timeInfo}`]):""
    //   )})
    setTimeData([...timeData, RequestDate]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitError("");
    const { day } = Object.keys(selectedTime).length ? selectedTime : {};
    const scheduleExists = mappingArray.some((el2) =>
      el2?.day?.shift?.some((el3) => el3?.isSelect === true)
    );

    if (!scheduleExists && !day) {
      setSubmitError(t("Times must be selected from the list"));
      return;
    }
    window.gtag("event", "new-request-time", {});
    if ((day && day === "next48hours") || scheduleExists) {
      setformData((prev) => ({
        ...prev,
        time:
          selectedTime.map &&
          selectedTime.map((d) => {
            return moment(d).toDate();
          }),
        emergencyTime: selectedTime,
        mappingArray: mappingArray,
      }));
      setDetailSec(true);
      window.scrollTo({ top: 0, behavior: "smooth" });
      return;
    }

    return;
  };

  const handleEmergencyTime = () => {
    if (selectedTime?.day !== "next48hours") {
      setSelectedTime({ day: "next48hours", month: "", daySlot: "" });
    } else {
      setSelectedTime([]);
      mappingArray.map((el) => {
        return el?.day?.shift?.map((item) => {
          return (item.isSelect = false);
        });
      });
    }
    setFlag(!flag);
  };
  useEffect(() => {
    const { time, emergencyTime, mappingArray } = formData || {};
    if (time) {
      setMappingArray(mappingArray);
      setSelectedTime(emergencyTime);
    } else {
      getData();
    }
  }, [formData, getData]);
  const getmeetingPrice = async () => {
    const response = await MeetingPrice();
    setPriceAmount(response.data?.defaultPrice);
  };
  useEffect(() => {
    getmeetingPrice();
  }, []);

  const Filterdata = (data) => {
    return data.some((d) => d.isSelect == true) ? true : false;
  };
  const handleContinue = () => {
    setSelectStep("");
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      {!detailSec ? (
        <div className="favourable-times-section">
          <div className="favourable-times-title">
            <h6>{t("Choosing convenient times to make an appointment")}</h6>
          </div>
          <div className="favourable-section-inner">
            <div className="asap-grid">
              <div className="asap-grid-text">
                <h5>בהקדם האפשרי</h5>
                <p>**עד שני ימי עסקים</p>
              </div>
              <div className="asap-grid-toggle">
                <input type="checkbox" name="switch" id="switch" />
                <label for="switch" onClick={handleEmergencyTime}></label>
              </div>
            </div>
            <div className="form-title">
              <p
                dangerouslySetInnerHTML={{
                  __html: t("or on the dates in the Pacific later this week:"),
                }}
              />
            </div>

            <Form
              className="appointment-time-form"
              onSubmit={(event) => handleSubmit(event)}
            >
              {selectedTime?.day !== "next48hours" && (
                <>
                  <Table responsive>
                    <thead>
                      <tr>
                        {heading.map((head, i) => (
                          <th key={i}>{t(`${head}`)}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {mappingArray.map((el, index) => {
                        return (
                          <tr key={index}>
                            <td>
                              <strong>
                                {el.day.day +
                                  " " +
                                  `${el.day.currentdate}/${el.day.currentMonth}`}
                              </strong>
                            </td>
                            {el?.day?.shift?.map((item, index2) => {
                              return (
                                <td key={index2}>
                                  <Form.Check
                                    type="radio"
                                    label={t(
                                      `${
                                        lang === "EN"
                                          ? item.english
                                          : item.hebrew
                                      }`
                                    )}
                                    className={`selecttime ${
                                      item?.isSelect ? "selected-border" : ""
                                    }`}
                                    //name="group1"
                                    // checked={(item.english===selectedTime?.daySlot && el.day.currentdate===selectedTime?.day )?true:false}
                                    checked={item?.isSelect || false}
                                    readOnly
                                    onClick={() =>
                                      slectedTimeFunction(
                                        el,
                                        item.hebrew,
                                        index,
                                        index2,
                                        item?.isSelect || false
                                      )
                                    }
                                  />
                                </td>
                              );
                            })}
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                  <h6>מומלץ לבחור כמה שיותר מועדים</h6>
                </>
              )}

              <div className="continue-btn">
                {submitError && <p className="text-error">{submitError}</p>}
                <Button type="submit">{t("Continue")}</Button>
                {storagePartnerId === "64d326cd869f4f8664538775" ? (
                  <a
                    href="https://api.whatsapp.com/send?phone=972526001369"
                    target="_blank"
                  >
                    {t(
                      "Didn't find the field you were looking for? It is written to us"
                    )}
                  </a>
                ) : (
                  <a href="https://www.hidoc.co.il/contact">
                    {t("We will be happy to help")}
                  </a>
                )}
              </div>
            </Form>
          </div>
        </div>
      ) : (
        <div className="application-details">
          <div className="application-details-title">
            <h6>
              <span>{t("Approval")}</span> {t("request details")}
            </h6>
            <p
              dangerouslySetInnerHTML={{
                __html: t(
                  "For your information, sending the application here is free of charge"
                ),
              }}
            />
            <p>
              {t("If a consultation is determined, the cost will be")}{" "}
              {utm?.value
                ? utm?.value
                : refStep
                ? `850 ${t("NIS")}`
                : `${priceamount} ${t("NIS")}`}
              .
            </p>
          </div>
          <div className="application-info-content">
            <div className="application-info-grid">
              <div className="application-info">
                <h5>{t("The turn for")}</h5>
                <h6>{formData?.age === 1 ? t("Child") : t("Adult")}</h6>
              </div>
              <div className="application-info">
                <h5>{t("in the field")}</h5>
                <h6>
                  {lang === "HE"
                    ? formData?.consult?.heb
                    : formData?.consult?.eng}
                </h6>
              </div>
            </div>
            {doctorObj ? (
              <div className="application-info">
                <h5>{t("Doctor name")}</h5>
                <h6>
                  {`${doctorObj?.userDetails?.first_name} ${doctorObj?.userDetails?.last_name}`}
                </h6>
              </div>
            ) : null}
            <div className="application-info">
              <h5>{t("Reference details")}</h5>
              <h6>
                {formData?.reference !== ""
                  ? formData?.reference
                  : t("Reference details")}
              </h6>
            </div>

            <div className="application-info">
              <h5>{t("Favorable times")}</h5>
              {formData?.emergencyTime?.day === "next48hours" ? (
                <h6>{t("As soon as possible")}</h6>
              ) : (
                <div className="application-info-inner">
                  {formData?.mappingArray?.length > 0 &&
                    formData.mappingArray.map((d, i) => {
                      return Filterdata(d.day.shift) ? (
                        <h6>
                          <span key={i}>
                            {d.day.currentdate > 9
                              ? d.day.currentdate
                              : `0${d.day.currentdate}`}
                            .
                            {d.day.currentMonth > 9
                              ? d.day.currentMonth
                              : `0${d.day.currentMonth}`}
                            .{d.day.currentYear}
                          </span>
                          {d.day.shift.map((shift) => {
                            return shift.isSelect ? ` ${shift.hebrew} ` : null;
                          })}
                        </h6>
                      ) : null;
                    })}
                </div>
              )}
            </div>
          </div>
          <div className="continue-btn">
            <Button type="submit" onClick={handleContinue}>
              {t("Continue")}
            </Button>
            {storagePartnerId === "64d326cd869f4f8664538775" ? (
              <a
                href="https://api.whatsapp.com/send?phone=972526001369"
                target="_blank"
              >
                {t(
                  "Didn't find the field you were looking for? It is written to us"
                )}
              </a>
            ) : (
              <a href="https://www.hidoc.co.il/contact">
                {t("We will be happy to help")}
              </a>
            )}
          </div>

          {/* <div className="application-info">
            <div className="application-info-inner">
              {doctorObj
                ? t(
                    "After submitting an application, we will offer you dates within the time frame you chose and send for final approval before payment"
                  )
                : t(
                    "After sending the application, we will locate the specialist for you, at the time you chose, we will send you the details of the specialist for final approval, before payment."
                  )}
            </div>
          </div> */}
        </div>
      )}
    </>
  );
};
export default FavourableTimes;
